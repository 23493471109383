export const LOAD_UNREAD_NOTIFICATION_REQUEST = "LOAD_UNREAD_NOTIFICATION_REQUEST";
export const LOAD_UNREAD_NOTIFICATION_SUCCESS = "LOAD_UNREAD_NOTIFICATION_SUCCESS";
export const LOAD_UNREAD_NOTIFICATION_FAIL = "LOAD_UNREAD_NOTIFICATION_FAIL";

export const MARK_READ_NOTIFICATION_REQUEST = "MARK_READ_NOTIFICATION_REQUEST";
export const MARK_READ_NOTIFICATION_SUCCESS = "MARK_READ_NOTIFICATION_SUCCESS";
export const MARK_READ_NOTIFICATION_RESET = "MARK_READ_NOTIFICATION_RESET";
export const MARK_READ_NOTIFICATION_FAIL = "MARK_READ_NOTIFICATION_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const LOAD_ALLOCATED_CARD_REQUEST = "LOAD_ALLOCATED_CARD_REQUEST";
export const LOAD_ALLOCATED_CARD_SUCCESS = "LOAD_ALLOCATED_CARD_SUCCESS";
export const LOAD_ALLOCATED_CARD_FAIL = "LOAD_ALLOCATED_CARD_FAIL";
export const LOAD_ALLOCATED_CARD_RESET = "LOAD_ALLOCATED_CARD_RESET";


export const ALLOCATE_CARD_REQUEST = "ALLOCATE_CARD_REQUEST";
export const ALLOCATE_CARD_SUCCESS = "ALLOCATE_CARD_SUCCESS";
export const ALLOCATE_CARD_FAIL = "ALLOCATE_CARD_FAIL";
export const ALLOCATE_CARD_RESET = "ALLOCATE_CARD_RESET";

export const LOAD_DISBURSED_REQUEST = "LOAD_DISBURSED_REQUEST";
export const LOAD_DISBURSED_SUCCESS = "LOAD_DISBURSED_SUCCESS";
export const LOAD_DISBURSED_FAIL = "LOAD_DISBURSED_FAIL";
export const LOAD_DISBURSED_RESET = "LOAD_DISBURSED_RESET";

export const DISBURSED_CARD_DETAILS_REQUEST = "DISBURSED_CARD_DETAILS_REQUEST";
export const DISBURSED_CARD_DETAILS_SUCCESS = "DISBURSED_CARD_DETAILS_SUCCESS";
export const DISBURSED_CARD_DETAILS_FAIL = "DISBURSED_CARD_DETAILS_FAIL";

export const DISBURSE_CARD_REQUEST = "DISBURSE_CARD_REQUEST";
export const DISBURSE_CARD_SUCCESS = "DISBURSE_CARD_SUCCESS";
export const DISBURSE_CARD_FAIL = "DISBURSE_CARD_FAIL";
export const DISBURSE_CARD_RESET = "DISBURSE_CARD_RESET";

export const LOAD_CARD_TYPE_REQUEST = "LOAD_CARD_TYPE_REQUEST";
export const LOAD_CARD_TYPE_SUCCESS = "LOAD_CARD_TYPE_SUCCESS";
export const LOAD_CARD_TYPE_FAIL = "LOAD_CARD_TYPE_FAIL";

export const LOAD_NEW_ALLOTMENT_CARD_REQLIST_REQUEST = "LOAD_NEW_ALLOTMENT_CARD_REQLIST_REQUEST";
export const LOAD_NEW_ALLOTMENT_CARD_REQLIST_SUCCESS = "LOAD_NEW_ALLOTMENT_CARD_REQLIST_SUCCESS";
export const LOAD_NEW_ALLOTMENT_CARD_REQLIST_FAIL = "LOAD_NEW_ALLOTMENT_CARD_REQLIST_FAIL";

export const ACCEPT_NEW_CARD_ALLOTMENT_REQUEST = "ACCEPT_NEW_CARD_ALLOTMENT_REQUEST";
export const ACCEPT_NEW_CARD_ALLOTMENT_SUCCESS = "ACCEPT_NEW_CARD_ALLOTMENT_SUCCESS";
export const ACCEPT_NEW_CARD_ALLOTMENT_FAIL = "ACCEPT_NEW_CARD_ALLOTMENT_FAIL";
export const ACCEPT_NEW_CARD_ALLOTMENT_RESET = "ACCEPT_NEW_CARD_ALLOTMENT_RESET";

export const LOAD_VOID_CARD_HISTORY_REQUEST = "LOAD_VOID_CARD_HISTORY_REQUEST";
export const LOAD_VOID_CARD_HISTORY_SUCCESS = "LOAD_VOID_CARD_HISTORY_SUCCESS";
export const LOAD_VOID_CARD_HISTORY_FAIL = "LOAD_VOID_CARD_HISTORY_FAIL";

export const LOAD_ALL_CARD_REQ_HISTORY_REQUEST = "LOAD_ALL_CARD_REQ_HISTORY_REQUEST";
export const LOAD_ALL_CARD_REQ_HISTORY_SUCCESS = "LOAD_ALL_CARD_REQ_HISTORY_SUCCESS";
export const LOAD_ALL_CARD_REQ_HISTORY_FAIL = "LOAD_ALL_CARD_REQ_HISTORY_FAIL";

export const CREATE_CARD_COUNTER_REQUEST = "CREATE_CARD_COUNTER_REQUEST";
export const CREATE_CARD_COUNTER_SUCCESS = "CREATE_CARD_COUNTER_SUCCESS";
export const CREATE_CARD_COUNTER_FAIL = "CREATE_CARD_COUNTER_FAIL";
export const CREATE_CARD_COUNTER_RESET = "CREATE_CARD_COUNTER_RESET";

export const LOAD_CARD_COUNTER_REQUEST = "LOAD_CARD_COUNTER_REQUEST";
export const LOAD_CARD_COUNTER_SUCCESS = "LOAD_CARD_COUNTER_SUCCESS";
export const LOAD_CARD_COUNTER_FAIL = "LOAD_CARD_COUNTER_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

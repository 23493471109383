export const LOAD_PARTNER_REQUEST = "LOAD_PARTNER_REQUEST";
export const LOAD_PARTNER_SUCCESS = "LOAD_PARTNER_SUCCESS";
export const LOAD_PARTNER_FAIL = "LOAD_PARTNER_FAIL";

export const CREATE_PARTNER_REQUEST = "CREATE_PARTNER_REQUEST";
export const CREATE_PARTNER_SUCCESS = "CREATE_PARTNER_SUCCESS";
export const CREATE_PARTNER_FAIL = "CREATE_PARTNER_FAIL";
export const CREATE_PARTNER_RESET = "CREATE_PARTNER_RESET";

export const UPDATE_PARTNER_REQUEST = "UPDATE_PARTNER_REQUEST";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_FAIL = "UPDATE_PARTNER_FAIL";
export const UPDATE_PARTNER_RESET = "UPDATE_PARTNER_RESET";

export const LOAD_PARTNER_DETAILS_REQUEST = "LOAD_PARTNER_DETAILS_REQUEST";
export const LOAD_PARTNER_DETAILS_SUCCESS = "LOAD_PARTNER_DETAILS_SUCCESS";
export const LOAD_PARTNER_DETAILS_FAIL = "LOAD_PARTNER_DETAILS_FAIL";

export const LOAD_PARTNER_SERVICE_REQUEST = "LOAD_PARTNER_SERVICE_REQUEST";
export const LOAD_PARTNER_SERVICE_SUCCESS = "LOAD_PARTNER_SERVICE_SUCCESS";
export const LOAD_PARTNER_SERVICE_FAIL = "LOAD_PARTNER_SERVICE_FAIL";

export const LOAD_PARTNER_SERVICE_DETAILS_REQUEST = "LOAD_PARTNER_SERVICE_DETAILS_REQUEST";
export const LOAD_PARTNER_SERVICE_DETAILS_SUCCESS = "LOAD_PARTNER_SERVICE_DETAILS_SUCCESS";
export const LOAD_PARTNER_SERVICE_DETAILS_FAIL = "LOAD_PARTNER_SERVICE_DETAILS_FAIL";

export const CREATE_PARTNER_SERVICE_REQUEST = "CREATE_PARTNER_SERVICE_REQUEST";
export const CREATE_PARTNER_SERVICE_SUCCESS = "CREATE_PARTNER_SERVICE_SUCCESS";
export const CREATE_PARTNER_SERVICE_FAIL = "CREATE_PARTNER_SERVICE_FAIL";
export const CREATE_PARTNER_SERVICE_RESET = "CREATE_PARTNER_SERVICE_RESET";

export const LOAD_PARTNER_TYPE_REQUEST = "LOAD_PARTNER_TYPE_REQUEST";
export const LOAD_PARTNER_TYPE_SUCCESS = "LOAD_PARTNER_TYPE_SUCCESS";
export const LOAD_PARTNER_TYPE_FAIL = "LOAD_PARTNER_TYPE_FAIL";

export const LOAD_PARTNER_SERVICE_TYPE_REQUEST = "LOAD_PARTNER_SERVICE_TYPE_REQUEST";
export const LOAD_PARTNER_SERVICE_TYPE_SUCCESS = "LOAD_PARTNER_SERVICE_TYPE_SUCCESS";
export const LOAD_PARTNER_SERVICE_TYPE_FAIL = "LOAD_PARTNER_SERVICE_TYPE_FAIL";

export const CREATE_PARTNER_SERVICE_TYPE_REQUEST = "CREATE_PARTNER_SERVICE_TYPE_REQUEST";
export const CREATE_PARTNER_SERVICE_TYPE_SUCCESS = "CREATE_PARTNER_SERVICE_TYPE_SUCCESS";
export const CREATE_PARTNER_SERVICE_TYPE_FAIL = "CREATE_PARTNER_SERVICE_TYPE_FAIL";
export const CREATE_PARTNER_SERVICE_TYPE_RESET = "CREATE_PARTNER_SERVICE_TYPE_RESET";

export const UPDATE_PARTNER_SERVICE_REQUEST = "UPDATE_PARTNER_SERVICE_REQUEST";
export const UPDATE_PARTNER_SERVICE_SUCCESS = "UPDATE_PARTNER_SERVICE_SUCCESS";
export const UPDATE_PARTNER_SERVICE_FAIL = "UPDATE_PARTNER_SERVICE_FAIL";
export const UPDATE_PARTNER_SERVICE_RESET = "UPDATE_PARTNER_SERVICE_RESET";

export const DELETE_PARTNER_SERVICE_REQUEST = "DELETE_PARTNER_SERVICE_REQUEST";
export const DELETE_PARTNER_SERVICE_SUCCESS = "DELETE_PARTNER_SERVICE_SUCCESS";
export const DELETE_PARTNER_SERVICE_RESET = "DELETE_PARTNER_SERVICE_RESET";
export const DELETE_PARTNER_SERVICE_FAIL = "DELETE_PARTNER_SERVICE_FAIL";

export const CREATE_PARTNER_TYPE_REQUEST = "CREATE_PARTNER_TYPE_REQUEST";
export const CREATE_PARTNER_TYPE_SUCCESS = "CREATE_PARTNER_TYPE_SUCCESS";
export const CREATE_PARTNER_TYPE_FAIL = "CREATE_PARTNER_TYPE_FAIL";
export const CREATE_PARTNER_TYPE_RESET = "CREATE_PARTNER_TYPE_RESET";

export const DELETE_PARTNER_REQUEST = "DELETE_PARTNER_REQUEST";
export const DELETE_PARTNER_SUCCESS = "DELETE_PARTNER_SUCCESS";
export const DELETE_PARTNER_RESET = "DELETE_PARTNER_RESET";
export const DELETE_PARTNER_FAIL = "DELETE_PARTNER_FAIL";

export const UPLOAD_PARTNER_IMAGE_REQUEST = "UPLOAD_PARTNER_IMAGE_REQUEST";
export const UPLOAD_PARTNER_IMAGE_SUCCESS = "UPLOAD_PARTNER_IMAGE_SUCCESS";
export const UPLOAD_PARTNER_IMAGE_FAIL = "UPLOAD_PARTNER_IMAGE_FAIL";
export const UPLOAD_PARTNER_IMAGE_RESET = "UPLOAD_PARTNER_IMAGE_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOAD_CUSTOMER_REQUEST = "LOAD_CUSTOMER_REQUEST";
export const LOAD_CUSTOMER_SUCCESS = "LOAD_CUSTOMER_SUCCESS";
export const LOAD_CUSTOMER_FAIL = "LOAD_CUSTOMER_FAIL";

export const LOAD_CONCERN_TYPE_REQUEST = "LOAD_CONCERN_TYPE_REQUEST";
export const LOAD_CONCERN_TYPE_SUCCESS = "LOAD_CONCERN_TYPE_SUCCESS";
export const LOAD_CONCERN_TYPE_FAIL = "LOAD_CONCERN_TYPE_FAIL";

export const LOAD_CUSTOMER_TYPE_REQUEST = "LOAD_CUSTOMER_TYPE_REQUEST";
export const LOAD_CUSTOMER_TYPE_SUCCESS = "LOAD_CUSTOMER_TYPE_SUCCESS";
export const LOAD_CUSTOMER_TYPE_FAIL = "LOAD_CUSTOMER_TYPE_FAIL";

export const LOAD_CUSTOMER_DETAILS_REQUEST = "LOAD_CUSTOMER_DETAILS_REQUEST";
export const LOAD_CUSTOMER_DETAILS_SUCCESS = "LOAD_CUSTOMER_DETAILS_SUCCESS";
export const LOAD_CUSTOMER_DETAILS_FAIL = "LOAD_CUSTOMER_DETAILS_FAIL";

export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAIL = "CREATE_CUSTOMER_FAIL";
export const CREATE_CUSTOMER_RESET = "CREATE_CUSTOMER_RESET";

export const APPROVE_CUSTOMER_REQUEST = "APPROVE_CUSTOMER_REQUEST";
export const APPROVE_CUSTOMER_SUCCESS = "APPROVE_CUSTOMER_SUCCESS";
export const APPROVE_CUSTOMER_FAIL = "APPROVE_CUSTOMER_FAIL";
export const APPROVE_CUSTOMER_RESET = "APPROVE_CUSTOMER_RESET";

export const DECLINE_CUSTOMER_REQUEST = "DECLINE_CUSTOMER_REQUEST";
export const DECLINE_CUSTOMER_SUCCESS = "DECLINE_CUSTOMER_SUCCESS";
export const DECLINE_CUSTOMER_RESET = "DECLINE_CUSTOMER_RESET";
export const DECLINE_CUSTOMER_FAIL = "DECLINE_CUSTOMER_FAIL";

export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_RESET = "DELETE_CUSTOMER_RESET";
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL";

export const CREATE_CUSTOMER_TYPE_REQUEST = "CREATE_CUSTOMER_TYPE_REQUEST";
export const CREATE_CUSTOMER_TYPE_SUCCESS = "CREATE_CUSTOMER_TYPE_SUCCESS";
export const CREATE_CUSTOMER_TYPE_FAIL = "CREATE_CUSTOMER_TYPE_FAIL";
export const CREATE_CUSTOMER_TYPE_RESET = "CREATE_CUSTOMER_TYPE_RESET";

export const UPLOAD_CUSTOMER_DATA_REQUEST = "UPLOAD_CUSTOMER_DATA_REQUEST";
export const UPLOAD_CUSTOMER_DATA_SUCCESS = "UPLOAD_CUSTOMER_DATA_SUCCESS";
export const UPLOAD_CUSTOMER_DATA_FAIL = "UPLOAD_CUSTOMER_DATA_FAIL";
export const UPLOAD_CUSTOMER_DATA_RESET = "UPLOAD_CUSTOMER_DATA_RESET";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL";
export const UPDATE_CUSTOMER_RESET = "UPDATE_CUSTOMER_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const LOAD_POINT_LIST_REQUEST = "LOAD_POINT_LIST_REQUEST";
export const LOAD_POINT_LIST_SUCCESS = "LOAD_POINT_LIST_SUCCESS";
export const LOAD_POINT_LIST_FAIL = "LOAD_POINT_LIST_FAIL";

export const CREATE_VENDOR_REQUEST = "CREATE_VENDOR_REQUEST";
export const CREATE_VENDOR_SUCCESS = "CREATE_VENDOR_SUCCESS";
export const CREATE_VENDOR_FAIL = "CREATE_VENDOR_FAIL";
export const CREATE_VENDOR_RESET = "CREATE_VENDOR_RESET";

export const LOAD_VENDOR_LIST_REQUEST = "LOAD_VENDOR_LIST_REQUEST";
export const LOAD_VENDOR_LIST_SUCCESS = "LOAD_VENDOR_LIST_SUCCESS";
export const LOAD_VENDOR_LIST_FAIL = "LOAD_VENDOR_LIST_FAIL";

export const CREATE_VENDOR_TYPE_REQUEST = "CREATE_VENDOR_TYPE_REQUEST";
export const CREATE_VENDOR_TYPE_SUCCESS = "CREATE_VENDOR_TYPE_SUCCESS";
export const CREATE_VENDOR_TYPE_FAIL = "CREATE_VENDOR_TYPE_FAIL";
export const CREATE_VENDOR_TYPE_RESET = "CREATE_VENDOR_TYPE_RESET";

export const LOAD_VENDOR_TYPE_REQUEST = "LOAD_VENDOR_TYPE_REQUEST";
export const LOAD_VENDOR_TYPE_SUCCESS = "LOAD_VENDOR_TYPE_SUCCESS";
export const LOAD_VENDOR_TYPE_FAIL = "LOAD_VENDOR_TYPE_FAIL";

export const LOAD_POINT_HISTORY_DETAILS_REQUEST = "LOAD_POINT_HISTORY_DETAILS_REQUEST";
export const LOAD_POINT_HISTORY_DETAILS_SUCCESS = "LOAD_POINT_HISTORY_DETAILS_SUCCESS";
export const LOAD_POINT_HISTORY_DETAILS_FAIL = "LOAD_POINT_HISTORY_DETAILS_FAIL";

export const LOAD_TOTAL_CASHOUT_POINT_REQUEST = "LOAD_TOTAL_CASHOUT_POINT_REQUEST";
export const LOAD_TOTAL_CASHOUT_POINT_SUCCESS = "LOAD_TOTAL_CASHOUT_POINT_SUCCESS";
export const LOAD_TOTAL_CASHOUT_POINT_FAIL = "LOAD_TOTAL_CASHOUT_POINT_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const LOAD_LIFE_STYLE_REQUEST = "LOAD_LIFE_STYLE_REQUEST";
export const LOAD_LIFE_STYLE_SUCCESS = "LOAD_LIFE_STYLE_SUCCESS";
export const LOAD_LIFE_STYLE_FAIL = "LOAD_LIFE_STYLE_FAIL";

export const CREATE_LIFE_STYLE_REQUEST = "CREATE_LIFE_STYLE_REQUEST";
export const CREATE_LIFE_STYLE_SUCCESS = "CREATE_LIFE_STYLE_SUCCESS";
export const CREATE_LIFE_STYLE_FAIL = "CREATE_LIFE_STYLE_FAIL";
export const CREATE_LIFE_STYLE_RESET = "CREATE_LIFE_STYLE_RESET";

export const APPROVE_LIFE_STYLE_REQUEST = "APPROVE_LIFE_STYLE_REQUEST";
export const APPROVE_LIFE_STYLE_SUCCESS = "APPROVE_LIFE_STYLE_SUCCESS";
export const APPROVE_LIFE_STYLE_FAIL = "APPROVE_LIFE_STYLE_FAIL";
export const APPROVE_LIFE_STYLE_RESET = "APPROVE_LIFE_STYLE_RESET";

export const DECLINE_LIFE_STYLE_REQUEST = "DECLINE_LIFE_STYLE_REQUEST";
export const DECLINE_LIFE_STYLE_SUCCESS = "DECLINE_LIFE_STYLE_SUCCESS";
export const DECLINE_LIFE_STYLE_RESET = "DECLINE_LIFE_STYLE_RESET";
export const DECLINE_LIFE_STYLE_FAIL = "DECLINE_LIFE_STYLE_FAIL";

export const UPDATE_LIFE_STYLE_REQUEST = "UPDATE_LIFE_STYLE_REQUEST";
export const UPDATE_LIFE_STYLE_SUCCESS = "UPDATE_LIFE_STYLE_SUCCESS";
export const UPDATE_LIFE_STYLE_FAIL = "UPDATE_LIFE_STYLE_FAIL";
export const UPDATE_LIFE_STYLE_RESET = "UPDATE_LIFE_STYLE_RESET";

export const LOAD_LIFE_STYLE_DETAILS_REQUEST = "LOAD_LIFE_STYLE_DETAILS_REQUEST";
export const LOAD_LIFE_STYLE_DETAILS_SUCCESS = "LOAD_LIFE_STYLE_DETAILS_SUCCESS";
export const LOAD_LIFE_STYLE_DETAILS_FAIL = "LOAD_LIFE_STYLE_DETAILS_FAIL";

export const DELETE_LIFE_STYLE_REQUEST = "DELETE_LIFE_STYLE_REQUEST";
export const DELETE_LIFE_STYLE_SUCCESS = "DELETE_LIFE_STYLE_SUCCESS";
export const DELETE_LIFE_STYLE_RESET = "DELETE_LIFE_STYLE_RESET";
export const DELETE_LIFE_STYLE_FAIL = "DELETE_LIFE_STYLE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

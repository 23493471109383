export const GENERATE_USER_REPORT_REQUEST = "GENERATE_USER_REPORT_REQUEST";
export const GENERATE_USER_REPORT_SUCCESS = "GENERATE_USER_REPORT_SUCCESS";
export const GENERATE_USER_REPORT_FAIL = "GENERATE_USER_REPORT_FAIL";
export const GENERATE_USER_REPORT_RESET = "GENERATE_USER_REPORT_RESET";

export const GENERATE_PARTNER_REPORT_REQUEST = "GENERATE_PARTNER_REPORT_REQUEST";
export const GENERATE_PARTNER_REPORT_SUCCESS = "GENERATE_PARTNER_REPORT_SUCCESS";
export const GENERATE_PARTNER_REPORT_FAIL = "GENERATE_PARTNER_REPORT_FAIL";
export const GENERATE_PARTNER_REPORT_RESET = "GENERATE_PARTNER_REPORT_RESET";

export const GENERATE_CUSTOMER_REPORT_REQUEST = "GENERATE_CUSTOMER_REPORT_REQUEST";
export const GENERATE_CUSTOMER_REPORT_SUCCESS = "GENERATE_CUSTOMER_REPORT_SUCCESS";
export const GENERATE_CUSTOMER_REPORT_FAIL = "GENERATE_CUSTOMER_REPORT_FAIL";
export const GENERATE_CUSTOMER_REPORT_RESET = "GENERATE_CUSTOMER_REPORT_RESET";

export const GENERATE_CASHOUT_REPORT_REQUEST = "GENERATE_CASHOUT_REPORT_REQUEST";
export const GENERATE_CASHOUT_REPORT_SUCCESS = "GENERATE_CASHOUT_REPORT_SUCCESS";
export const GENERATE_CASHOUT_REPORT_FAIL = "GENERATE_CASHOUT_REPORT_FAIL";
export const GENERATE_CASHOUT_REPORT_RESET = "GENERATE_CASHOUT_REPORT_RESET";

export const LOAD_REPORT_REQUEST = "LOAD_REPORT_REQUEST";
export const LOAD_REPORT_SUCCESS = "LOAD_REPORT_SUCCESS";
export const LOAD_REPORT_FAIL = "LOAD_REPORT_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

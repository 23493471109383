export const LOAD_INTERNAL_USER_REQUEST = "LOAD_INTERNAL_USER_REQUEST";
export const LOAD_INTERNAL_USER_SUCCESS = "LOAD_INTERNAL_USER_SUCCESS";
export const LOAD_INTERNAL_USER_FAIL = "LOAD_INTERNAL_USER_FAIL";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const CREATE_USER_RESET = "CREATE_USER_RESET";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";

export const LOAD_USER_TYPE_REQUEST = "LOAD_USER_TYPE_REQUEST";
export const LOAD_USER_TYPE_SUCCESS = "LOAD_USER_TYPE_SUCCESS";
export const LOAD_USER_TYPE_FAIL = "LOAD_USER_TYPE_FAIL";

export const CREATE_DESIGNATION_REQUEST = "CREATE_DESIGNATION_REQUEST";
export const CREATE_DESIGNATION_SUCCESS = "CREATE_DESIGNATION_SUCCESS";
export const CREATE_DESIGNATION_FAIL = "CREATE_DESIGNATION_FAIL";
export const CREATE_DESIGNATION_RESET = "CREATE_DESIGNATION_RESET";

export const LOAD_DESIGNATION_REQUEST = "LOAD_DESIGNATION_REQUEST";
export const LOAD_DESIGNATION_SUCCESS = "LOAD_DESIGNATION_SUCCESS";
export const LOAD_DESIGNATION_FAIL = "LOAD_DESIGNATION_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_RESET = "DELETE_USER_RESET";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const CREATE_USER_TYPE_REQUEST = "CREATE_USER_TYPE_REQUEST";
export const CREATE_USER_TYPE_SUCCESS = "CREATE_USER_TYPE_SUCCESS";
export const CREATE_USER_TYPE_FAIL = "CREATE_USER_TYPE_FAIL";
export const CREATE_USER_TYPE_RESET = "CREATE_USER_TYPE_RESET";

export const LOAD_INTERNAL_USER_BY_ID_REQUEST = "LOAD_INTERNAL_USER_BY_ID_REQUEST";
export const LOAD_INTERNAL_USER_BY_ID_SUCCESS = "LOAD_INTERNAL_USER_BY_ID_SUCCESS";
export const LOAD_INTERNAL_USER_BY_ID_FAIL = "LOAD_INTERNAL_USER_BY_ID_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const LOAD_NEWS_REQUEST = "LOAD_NEWS_REQUEST";
export const LOAD_NEWS_SUCCESS = "LOAD_NEWS_SUCCESS";
export const LOAD_NEWS_FAIL = "LOAD_NEWS_FAIL";

export const CREATE_NEWS_REQUEST = "CREATE_NEWS_REQUEST";
export const CREATE_NEWS_SUCCESS = "CREATE_NEWS_SUCCESS";
export const CREATE_NEWS_FAIL = "CREATE_NEWS_FAIL";
export const CREATE_NEWS_RESET = "CREATE_NEWS_RESET";

export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_RESET = "DELETE_NEWS_RESET";
export const DELETE_NEWS_FAIL = "DELETE_NEWS_FAIL";

export const LOAD_NEWS_DETAILS_REQUEST = "LOAD_NEWS_DETAILS_REQUEST";
export const LOAD_NEWS_DETAILS_SUCCESS = "LOAD_NEWS_DETAILS_SUCCESS";
export const LOAD_NEWS_DETAILS_FAIL = "LOAD_NEWS_DETAILS_FAIL";

export const UPDATE_NEWS_REQUEST = "UPDATE_NEWS_REQUEST";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_FAIL = "UPDATE_NEWS_FAIL";
export const UPDATE_NEWS_RESET = "UPDATE_NEWS_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
